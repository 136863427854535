import Storage from './Storage'
import Cookies from 'js-cookie'
import { clearCookie } from '../views/lib/sso'
const Tool = (function () {
  let tool = {
    ss: {
      storage: new Storage({ source: window.sessionStorage })
    },
    ls: {
      storage: new Storage({ source: window.localStorage })
    }
  }
  tool.setCookie2 = function (name, value, options = { expires: 60 }) {
    Cookies.set(name, value, options)
  }
  // 设置cookie值
  tool.setCookie = function (name, value, seconds) {
    var exdate = new Date()
    exdate.setTime(exdate.getTime() + seconds)
    document.cookie = name + '=' + encodeURI(value) + (seconds == null ? '' : ';expires=' + exdate.toGMTString())
  }
  // 获取cookies值
  tool.getCookie = function (name) {
    if (document.cookie.length > 0) {
      var start = document.cookie.indexOf(name + '=')
      if (start !== -1) {
        start = start + name.length + 1
        var end = document.cookie.indexOf(';', start)
        if (end === -1) end = document.cookie.length
        return decodeURI(document.cookie.substring(start, end))
      }
    }
    return ''
  }
  // 清楚本地cookie
  tool.clearCookie = function () {
    if (document.cookie.length > 0) {
      console.log('-----clearCookie', document.cookie)
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
      if (keys) {
        for (var i = keys.length; i--; ) document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
      }
    }
  }

  // 缓存值
  tool.ss.setItem = tool.ls.setItem = function (key, val, expired) {
    this.storage.setItem(key, val, expired)
  }
  // 获取值
  tool.ss.getItem = tool.ls.getItem = function (key) {
    return this.storage.getItem(key)
    //   return JSON.parse(this.storage.getItem(key))
    // } catch (err) {
    //   return null
    // }
  }
  // 删除缓存
  tool.ss.removeItem = tool.ls.removeItem = function (key) {
    this.storage.removeItem(key)
  }
  // 清除缓存
  tool.ss.clear = tool.ls.clear = function () {
    this.storage.clear()
  }
  tool.generateUUID = function () {
    var d = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
  }
  tool.isPC = function () {
    let userAgentInfo = navigator.userAgent
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return false
      }
    }
    return true
  }
  /**
   * 判断当前设备
   * @returns {string}
   */
  tool.isIosOrAndroid = function () {
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    let isStr = ''
    if (isAndroid) {
      isStr = 'android'
    }
    if (isiOS) {
      isStr = 'ios'
    }
    return isStr
  }
  // 动态加载css，id参数用于标示唯一，非必需
  tool.loadCSS = function (url, id, className) {
    if (id) {
      let dom = document.getElementById(id)
      if (dom) {
        dom.href = url
        return
      }
    }
    let link = document.createElement('link')
    id && (link.id = id)
    className && (link.className = className)
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = url
    document.querySelector('head').appendChild(link)
  }
  // 动态加载js，id参数用于唯一标示，非必需
  tool.loadScript = function (url, callback, id, className) {
    console.log('loadScript' + new Date().getTime())
    if (id) {
      let dom = document.getElementById(id)
      if (dom) {
        let flag = false
        callback && callback(flag)
        return
      }
    }
    var script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    if (id) {
      script.setAttribute('id', id)
    }
    if (className) {
      script.setAttribute('class', className)
    }
    if (callback) {
      script.onload = script.onreadystatechange = function () {
        console.log(' script.onload url=' + url, this.readyState)
        let flag = !this.readyState || this.readyState === 'loaded' || this.readyState === 'complete'
        callback(flag)
      }
      script.onerror = function (res) {
        console.error(' script.onerror ', res)
        let flag = false
        callback(flag)
      }
    }
    script.setAttribute('src', url)
    document.body.appendChild(script)
  }

  tool.clearCookieToken = function () {
    console.log('clear Token....')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    clearCookie()
    console.log('已清除')
  }
  tool.loadVconsole = function () {
    // if(typeof (VConsole) !== 'undefined'){
    //   alert('已经打开控制台!')
    //   console.log('已经打开控制台')
    //   return
    // }
    let open_debug = localStorage.getItem('OPEN_DEBUG')
    if (!open_debug || open_debug == 'false') {
      localStorage.setItem('OPEN_DEBUG', 'true')
      new VConsole()
      // const urlJs = 'https://static.service.z-trip.cn/app/ztrip/vconsole.js';
      // tool && tool.loadScript(urlJs, function (rs) {
      //   if (rs) {
      //     let vc = new VConsole()
      //     window.isOpenVConsole = true
      //     console.log(vc)
      //   } else {
      //     alert('加载调试js错误')
      //   }
      // })
    } else {
      // alert('已经打开控制台')
      localStorage.removeItem('OPEN_DEBUG')
      console.log('已经打开控制台')
      window.location.reload()
    }
  }
  tool.getUserEnv = function () {
    return new Promise((resolve, reject) => {
      window.$httpUtil &&
        window.$httpUtil.instance
          .get('/jsonp/v2/user/env', {
            baseURL: window.$ZConfig.getPassportUrl()
          })
          .then(({ data }) => {
            resolve(Object.assign({ code: '0' }, data.data || {}))
          })
          .catch((err) => {
            reject({
              code: '-1',
              err
            })
          })
    })
  }

  tool.logger = function (key, object) {
    if (typeof window._ztrace == 'undefined') {
      console.error('window._ztrace 尚未挂载')
      return
    }
    if (typeof object != 'object') {
      console.error('log 函数请传入 object参数')
      return
    }
    window._ztrace && window._ztrace.log(key, object)
  }
  tool.block = function (data = {}) {
    if (window.flutter_inappwebview) {
      window.$flutter.block(data.redirect || location.href)
    }
  }

  return tool
})()
window.$tool = Tool
