import { navigateToUrl } from 'single-spa'
import { $t } from './i18n'
;(function () {
  var inApp =
    navigator.userAgent.indexOf('APICloud') != -1 ||
    window.location.search.indexOf('from=app') != -1 ||
    !!window.flutter_inappwebview
  const isFlutter = window.flutter_inappwebview ? true : false
  var ua = window.navigator.userAgent.toLowerCase()
  var wxwork = ua.indexOf('wxwork/') !== -1 //企业微信
  var isMiniprogram = ua.indexOf('miniprogram') !== -1
  var jump = {
    orderTypes: {
      10: 'flight',
      11: 'hotel',
      13: 'hotel',
      12: 'train',
      16: 'car',
      17: 'flight',
      14: 'otapub/general' // 特殊处理
    },
    // env: {
    //   app:   navigator.userAgent.indexOf('APICloud') != -1 ||  navigator.userAgent.indexOf(‘ztflutter’) != -1 , // 老的ios的还是apicloud,新的andriod是ztflutter
    //   flutter: navigator.userAgent.indexOf(‘ztflutter’) != -1  ,
    //   dd: ua.indexOf('dingtalk') !== -1,
    //   weixin: ua.match(/MicroMessenger/i) == 'micromessenger' && !wxwork && !isMiniprogram,  // 公众号
    //   miniprogram: ua.indexOf('miniprogram') !== -1,   // 小程序
    //   feishu: ua.indexOf('lark/') !== -1, //飞书
    //   wxwork:  ua.indexOf('wxwork/') !== -1 // 企业微信
    // }
    env: {
      app: inApp,
      flutter: isFlutter,
      // 在iframe中,因dd无法响应ready事件执行cookie自动登录逻辑
      dd: ua.indexOf('dingtalk') !== -1 && self == top,
      weixin: ua.match(/MicroMessenger/i) == 'micromessenger' && !wxwork && !isMiniprogram,
      miniprogram: isMiniprogram,
      feishu: ua.indexOf('lark/') !== -1, //飞书
      wxwork: wxwork,
      yy: ua.indexOf('qyzone') !== -1 // 用友
    }
  }
  var hostname = window.location.hostname
  var domain = hostname.substr(hostname.indexOf('.') + 1)
  var prefixDomain = hostname.substr(0, hostname.indexOf('.'))
  jump.origin = window.location.protocol + `//${prefixDomain}.` + domain
  // 跳转至登录页，只有common项目才可传v这个参数
  jump.login = function (vue) {
    if (!vue) {
      alert($t('que-shao-can-shu-vue'))
      return
    }
    if (this.env.weixin) {
      let openId = localStorage.getItem('openId')
      // 确保有微信ID
      if (!openId) {
        window.location.replace($ZConfig.get('DOMAIN_H5') + '/common/home.html')
        return
      }
    }
    // 在flutter环境
    if (window.flutter_inappwebview) {
      // 需要跳转到flutter原生登录页面;
      window.flutter_inappwebview.callHandler('replacePage', JSON.stringify({ pageName: '/login' }))
    } else {
      let loginUrl = $ZConfig.getVal('LOGIN_URL')
      if (loginUrl) {
        window.location.replace(loginUrl)
      } else {
        vue.$router.replace({ path: '/login' })
      }
    }
  }

  // 跨项目路由跳转,临时解决方案; 部分浏览器,跨项目路由跳转publicpath有问题
  jump.goLocPage = function (pageUrl = '/home', router = null, isReplace = false) {
    // 如果是国泰君安项目,返回的不是/home
    let H5_HOME_URL = $ZConfig && $ZConfig.getVal('H5_HOME_URL')
    if (pageUrl === '/home') {
      if (
        window.navigator.userAgent.toLowerCase().indexOf('alllink') !== -1 ||
        (H5_HOME_URL && H5_HOME_URL.indexOf('common/gtjaMiddlePage') !== -1)
      ) {
        pageUrl = '/common/gtjaMiddlePage'
      }
    }
    if (isReplace) {
      history.replaceState(null, null, pageUrl)
    } else {
      navigateToUrl(pageUrl)
    }

    console.log(pageUrl, router, isReplace)
  }
  jump.goGatePage = function (pageUrl, isReplace = true, channel = 'jump_js') {
    window.zzzconfig && window.zzzconfig.goGatePage(pageUrl, isReplace, channel)
    // let prefixStr = location.origin + '/gate.html'
    // if (typeof window.flutter_inappwebview !== 'undefined') {
    //   prefixStr = location.origin + '/sso.html/flutter'
    // }
    // const _returnUrl = prefixStr + '?url=' + encodeURIComponent(pageUrl)
    // if (isReplace) {
    //   location.replace(_returnUrl)
    // } else {
    //   location.href = _returnUrl
    // }
  }
  // 返回
  jump.back = function (router) {
    // if(typeof router === 'undefined') {
    //   router = window.topRouter
    // }
    if (navigator.userAgent.indexOf('MSIE') >= 0 && navigator.userAgent.indexOf('Opera') < 0) {
      // IE
      if (history.length > 0) {
        // window.history.go(-1);
        router.back()
      } else {
        router.replace('/home')
      }
    } else {
      // 非IE浏览器
      if (
        navigator.userAgent.indexOf('Firefox') >= 0 ||
        navigator.userAgent.indexOf('Opera') >= 0 ||
        navigator.userAgent.indexOf('Safari') >= 0 ||
        navigator.userAgent.indexOf('Chrome') >= 0 ||
        navigator.userAgent.indexOf('WebKit') >= 0
      ) {
        if (window.history.length > 1) {
          router.back()
        } else {
          router.replace('/home')
        }
      } else {
        // 未知的浏览器
        router.back()
      }
    }
  }
  jump.errorToLogin = function (vue, tip) {
    vue.$w.toast.show({
      type: 'cancel',
      text: tip || $t('FWYC'),
      onHide() {
        jump.login(vue)
      }
    })
  }
  jump.errorToHome = function (vue, tip) {
    vue.$w.toast.show({
      type: 'cancel',
      text: tip || $t('FWYC'),
      onHide: () => {
        jump.goLocPage()
      }
    })
  }
  jump.isWeiXin = function () {
    var ua = window.navigator.userAgent.toLowerCase()
    return ua.match(/MicroMessenger/i) == 'micromessenger'
  }
  // to订单明细,如果有backName,视为当前页面不可返回，使用replace跳转
  jump.orderDetail = function (vue, orderId, backName) {
    var pre = orderId.substr(0, 2)
    var bizType = this.orderTypes[pre]
    if (bizType) {
      var defaultPath = bizType + '/orderDetail'
      if (pre == '17') {
        // 国际机票
        defaultPath = 'flight/intlOrderDetail'
      }
      var path = '/' + defaultPath + '?orderId=' + orderId
      if (backName) {
        if (backName.startsWith('backPath=')) {
          path += '&' + backName
        } else {
          path += '&backName=' + backName
        }
      }
      vue.$router.push(path)
    } else {
      vue.$router.replace({ path: '/home' })
    }
  }
  // 跳转至支付控制台
  jump.toPay = function (vue, orderId, query, allowBack) {
    let params = Object.assign({}, { orderId }, query)
    vue.$router[allowBack ? 'push' : 'replace']({
      path: '/common/pay',
      query: params
    })
  }

  // 跳转至发票详情
  jump.toReceiptDetail = function (orderId) {
    this.to('/common/receipt/detail', { orderId }, 'common')
  }
  // 下载app
  jump.openAppDownLoad = function () {
    let link = $ZConfig.getVal('app-link') || 'https://www.pgyer.com/1ySw'
    if (window.api.systemType == 'ios') {
      api.openApp({
        iosUrl: link
      })
    } else if (window.api.systemType == 'android') {
      api.openApp(
        {
          androidPkg: 'android.intent.action.VIEW',
          mimeType: 'text/html',
          uri: link
        },
        function (ret, err) {
          if (err) {
            console.error(err)
            alert($t('da-kai-xia-zai-lian-jie-yi-cha'))
          }
        }
      )
    } else {
      alert($t('zan-bu-zhi-chi-de-ji-xing-qing'))
    }
  }
  jump.openLink = function (link) {
    if (jump.env.app) {
      let browser = window.api.require('webBrowser')
      if (browser) {
        browser.open({ url: link })
      } else {
        // window.api.confirm({
        //   title: '提示',
        //   msg: '您当前的APP版本无法使用在线客服，请下载最新版本',
        //   buttons: ['立即下载', '我再想想']
        // }, function(ret, err) {
        //   let index = ret.buttonIndex;
        //   if (index == 1) {
        //     jump.openAppDownLoad()
        //   }
        // })
        window.location.href = link
      }
    } else if (jump.env.dd) {
      dd.biz.util.openLink({
        url: link,
        onSuccess: function (result) {
          console.log(result)
        },
        onFail: function (err) {
          alert($t('lian-jie-da-kai-shi-bai') + err)
        }
      })
    } else if (jump.env.feishu) {
      window.h5sdk.biz.util.openLink({
        url: link,
        title: ''
      })
    } else {
      window.location.href = link
    }
  }
  window.$jump = jump
})()
