/**
 * rewrite localStorage
 */
export default class storage {
  constructor(props) {
    this.props = props || {}
    this.source = this.props.source || window.localStorage
    this.initRun()
  }
  initRun() {
    const reg = new RegExp('__expires__')
    let data = this.source
    let list = Object.keys(data)
    if (list.length > 0) {
      list.map((key) => {
        if (!reg.test(key)) {
          let now = Date.now()
          let expires = data[`${key}__expires__`] || Date.now + 1
          if (now >= expires) {
            this.removeItem(key)
          }
        }
        return key
      })
    }
  }
  setItem(key, value, expired) {
    let source = this.source
    source[key] = JSON.stringify(value)
    if (expired) {
      source[`${key}__expires__`] = Date.now() + 1000 * 60 * expired
    }
    return value
  }
  getItem(key) {
    const source = this.source
    let expired = source[`${key}__expires__`] || Date.now + 1
    const now = Date.now()
    if (now >= expired) {
      this.removeItem(key)
      return
    }
    const value = source[key] ? JSON.parse(source[key]) : source[key]
    return value
  }
  removeItem(key) {
    const data = this.source
    let value = data[key]
    delete data[key]
    delete data[`${key}__expires__`]
    return value
  }
  clear() {
    this.source.clear()
  }
}
